<template>
    <div class="mt-6">
        <div class="dark:text-white">
            Parâmetros para cálculos da velocidade dos produtos:
        </div>
        
        <!-- Receita -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Receita
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.positive_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Referência de Baixa Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/4">
                            <p class="flex items-center">
                                Referência de Alta Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.revenue.min"
                                required
                                min="0"
                                max="2000000000"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.revenue.max"
                                required
                                min="0"
                                max="2000000000"
                            />
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.revenue.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- Quantidade de Vendas -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Quantidade de Vendas
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.positive_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Referência de Baixa Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/4">
                            <p class="flex items-center">
                                Referência de Alta Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700 w-1/4">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.sales_quantity.min"
                                required
                                min="0"
                                max="2000000000"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.sales_quantity.max"
                                required
                                min="0"
                                max="2000000000"
                            />
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.sales_quantity.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- Percentual de Conversão -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Percentual de Conversão
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.positive_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Referência de Baixa Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/4">
                            <p class="flex items-center">
                                Referência de Alta Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800  w-1/4">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800  w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.conversion_percentage.min"
                                required
                                min="0"
                                max="100"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.conversion_percentage.max"
                                required
                                min="0"
                                max="100"
                            />
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.conversion_percentage.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- Quantidade de Vendas de Afiliados -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Quantidade de Vendas de Afiliados
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.positive_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Referência de Baixa Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/4">
                            <p class="flex items-center">
                                Referência de Alta Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-80 w-1/4">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800  w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.affiliate_sales_quantity.min"
                                required
                                min="0"
                                max="2000000000"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.affiliate_sales_quantity.max"
                                required
                                min="0"
                                max="2000000000"
                            />
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.affiliate_sales_quantity.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- Percentual de Reembolsos -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Percentual de Reembolso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.negative_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Referência de Baixa Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/4">
                            <p class="flex items-center">
                                Referência de Alta Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.refund.max"
                                required
                                min="0"
                                max="100"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.refund.min"
                                required
                                min="0"
                                max="100"
                            />
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.refund.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- Percentual de Chargebacks -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Percentual de Chargeback
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.negative_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Referência de Baixa Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/4">
                            <p class="flex items-center">
                                Referência de Alta Performance
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.chargeback.max"
                                required
                                min="0"
                                max="100"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.chargeback.min"
                                required
                                min="0"
                                max="100"
                            />
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.chargeback.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- Dias desde o lançamento -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/4" colspan="4">
                            <p class="flex items-center">
                                Dias Desde o Lançamento
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.days_since_launch_effect"
                                />
                            </p>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/3">
                            <p class="flex items-center">
                                Peso
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.weight"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/3">
                            <p class="flex items-center">
                                Período
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.days_since_launch"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/3">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.days_since_launch.weight"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/3">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.days_since_launch.period"
                                required
                                min="0"
                                max="365"
                            />
                        </td>
                    </tr>
                </tbody>  
            </table>
        </div>
        
        <!-- Velocidades -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800" colspan="4">Configurações Gerais</th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/3">
                            <p class="flex items-center">
                                Velocidade Mínima
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_minimum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 w-1/3">
                            <p class="flex items-center">
                                Velocidade Máxima
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.reference_maximum"
                                />
                            </p>
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800 w-1/3">
                            <p class="flex items-center">
                                Período
                                <ExclamationCircleIcon
                                    class="h-4 w-4 mr-1"
                                    aria-hidden="true"
                                    v-tooltip="tooltipText.period"
                                />
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/3">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.speed.min"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                        <td class="py-4 px-4 w-1/3">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.speed.max"
                                required
                                min="0"
                                max="1000"
                            />
                        </td>
                        <td scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800 w-1/3">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500"
                                type="number"
                                v-model="setting.period"
                                required
                                min="0"
                                max="365"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <button
            @click.prevent="update"
            :disabled="false"
            type="button"
                class="mr-2 inline-flex items-center px-10 py-2 h-10 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500"
        >
            Salvar
        </button>
        
        <!-- Calculadora -->
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600 mt-16">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800" colspan="7">Calculadora Interativa de Velocidade</th>
                    </tr>
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Receita
                        </th>
                        <th scope="col" class="py-2 px-4">
                            Qtd. de vendas
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Percentual de conversão
                        </th>
                        <th scope="col" class="py-2 px-4">
                            Qtd. de vendas de afiliados
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            % Reembolso
                        </th>
                        <th scope="col" class="py-2 px-4">
                            % Chargeback
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Dias
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.revenue">
                        </th>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.sales_quantity">  
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.conversion_percentage">
                        </td>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.affiliate_sales_quantity">
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.refund">
                        </td>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.chargeback">
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="calculator.days_since_launch">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>  
            <h3 class="font-semibold text-center text-gray-800 mt-6">
                1ª Parte - Como funciona a normalização
            </h3>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600" v-html="normalizacaoExemplo"></div>

            <h3 class="font-semibold text-center text-gray-800 mt-6">
                2ª Parte - O valor normalizado dos valores com base nas configurações (O valor de dias desde o lançamento não é normalizado pois funciona como um impulso)
            </h3>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600" v-html="equacaoNormalizacao"></div>

            <h3 class="font-semibold text-center text-gray-800 mt-6">
                3ª Parte - A soma dos valores com seus respectivos pesos
            </h3>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600" v-html="equacaoPesos"></div>

            <h3 class="font-semibold text-center text-gray-800 mt-6">
                4ª Parte - O valor da equação acima é convertida em uma velocidade com base no máximo e mínimo teórico obtido com os pesos
            </h3>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600" v-html="theoricalEquation"></div>
        </div>
    </div>

</template>

<script>
import { ref, reactive, computed } from 'vue';
import VueSelect from "../../components/forms/VueSelect.vue";
import { useToast } from "vue-toastification";
import katex from "katex";
import "katex/dist/katex.min.css";
import {
    ExclamationCircleIcon,
} from "@heroicons/vue/24/outline";

export default {
    components: { VueSelect, ExclamationCircleIcon },
    setup() {
        const toast = useToast();
        const selected = ref(null);
        const loading = ref(false);
        const calculator = reactive({
            revenue: 0,
            sales_quantity: 0,
            conversion_percentage: 0,
            affiliate_sales_quantity: 0,
            refund: 0,
            chargeback: 0,
            days_since_launch: 0,
        });
        const setting = reactive({
            revenue: {
                min: 10000,
                max: 500000,
                weight: 20,
            },
            sales_quantity: {
                min: 100,
                max: 5000,
                weight: 50,
            },
            conversion_percentage: {
                min: 1,
                max: 6.5,
                weight: 10,
            },
            affiliate_sales_quantity: {
                min: 10,
                max: 500,
                weight: 10,
            },
            refund: {
                min: 1.5,
                max: 5,
                weight: 5,
            },
            chargeback: {
                min: 0.5,
                max: 2.5,
                weight: 7,
            },
            days_since_launch: {
                weight: 1,
                period: 30
            },
            speed: {
                min: 30,
                max: 240
            },
            period: 30
        });

        const tooltipText = {
            reference_minimum: "Referência para um valor considerado 'ruim'",
            reference_maximum: "Referência para um valor considerado 'excelente'",
            weight: "O peso que esse valor terá na equação de velocidade",
            period: "O período dos últimos X dias que serão considerados para os cálculos",
            days_since_launch: "O período em que o produto é considerado novo e recebe um impulso a mais na velocidade",
            positive_effect: "Esse campo tem um efeito positivo na velocidade e quanto mais perto do máximo melhor",
            negative_effect: "Esse campo tem um efeito negativo na velocidade e quanto mais perto do mínimo melhor",
            days_since_launch_effect: "Esse campo tem efeito positivo, mas diminui conforme o tempo e é zerado após o período configurado",
        };

        const fetch = () => {
            loading.value = true;
            axios.get('/api/showcase/setting').then(({ data }) => {              
                Object.assign(setting, data.setting);
                loading.value = false;
            }).catch(({ response }) => {
                loading.value = false;
            });
        };

        const updateSetting = () => {
            axios.put('/api/showcase/setting', {
                setting: setting
            }).then(({ response }) => {
                toast.success("Configuração salva com sucesso!", { timeout: 2000 });
            }).catch(({ response }) => {
                response.data.errors.forEach(element => {
                    toast.error(element, { timeout: 3000 });
                });
            });
        };

        const update = () => {
            if(setting.revenue.min < 0 || setting.revenue.max < 0 || setting.revenue.weight < 0 || setting.revenue.max < setting.revenue.min) {
                toast.error("Os valores de receita não podem ser negativos e a referência de baixa performance deve ser menor que a de alta performance.", { timeout: 5000 });
                return;
            }

            if(setting.sales_quantity.min < 0 || setting.sales_quantity.max < 0 || setting.sales_quantity.weight < 0 || setting.sales_quantity.max < setting.sales_quantity.min) {
                toast.error("Os valores de quantidade de vendas não podem ser negativos e a referência de baixa performance deve ser menor que a de alta performance.", { timeout: 5000 });
                return;
            }

            if(setting.conversion_percentage.min < 0 || setting.conversion_percentage.max < 0 || setting.conversion_percentage.weight < 0 || setting.conversion_percentage.max < setting.conversion_percentage.min) {
                toast.error("Os valores de percentual de conversão devem estar entre 0 e 100 e a referência de baixa performance deve ser menor que a de alta performance.", { timeout: 5000 });
                return;
            }

            if(setting.affiliate_sales_quantity.min < 0 || setting.affiliate_sales_quantity.max < 0 || setting.affiliate_sales_quantity.weight < 0 || setting.affiliate_sales_quantity.max < setting.affiliate_sales_quantity.min) {
                toast.error("Os valores de quantidade de vendas de afiliados não podem ser negativos e a referência de baixa performance deve ser menor que a de alta performance.", { timeout: 5000 });
                return;
            }

            if(setting.refund.min < 0 || setting.refund.max < 0 || setting.refund.weight < 0 || setting.refund.min > 100 || setting.refund.max > 100 || setting.refund.max < setting.refund.min) {
                toast.error("Os valores de percentual de reembolso devem estar entre 0 e 100 e, neste caso, a referência de baixa performance deve ser maior que a de alta performance.", { timeout: 5000 });
                return;
            }

            if(setting.chargeback.min < 0 || setting.chargeback.max < 0 || setting.chargeback.weight < 0 || setting.chargeback.min > 100 || setting.chargeback.max > 100 || setting.chargeback.max < setting.chargeback.min) {
                toast.error("Os valores de percentual de chargeback devem estar entre 0 e 100 e, neste caso, a referência de baixa performance deve ser maior que a de alta performance.", { timeout: 5000 });
                return;
            }

            if(setting.days_since_launch.weight < 0 || setting.days_since_launch.period < 1) {
                toast.error("Os valores de dias desde o lançamento não podem ser negativos.", { timeout: 5000 });
                return;
            }

            if(setting.speed.min < 0 || setting.speed.max < 0 || setting.period < 1 || setting.speed.max < setting.speed.min) {
                toast.error("Os valores de velocidade não podem ser negativos e a velocidade máxima deve ser maior que a mínima.", { timeout: 5000 });
                return;
            }

            updateSetting();
        };

        const normalizeValue = (value, min, max) => {
            // if (value < min) {
            //     return 0;
            // }

            return ((value - min) / (max - min)).toFixed(2);
        };

        const norm_revenue = computed(() => normalizeValue(calculator.revenue, setting.revenue.min, setting.revenue.max));
        const norm_sales_quantity = computed(() => normalizeValue(calculator.sales_quantity, setting.sales_quantity.min, setting.sales_quantity.max));
        const norm_conversion_percentage = computed(() => normalizeValue(calculator.conversion_percentage, setting.conversion_percentage.min, setting.conversion_percentage.max));
        const norm_affiliate_sales_quantity = computed(() => normalizeValue(calculator.affiliate_sales_quantity, setting.affiliate_sales_quantity.min, setting.affiliate_sales_quantity.max));
        const norm_refund = computed(() => normalizeValue(calculator.refund, setting.refund.min, setting.refund.max));
        const norm_chargeback = computed(() => normalizeValue(calculator.chargeback, setting.chargeback.min, setting.chargeback.max));
        const norm_days_since_launch = computed(() => {
            let value = 0;

            if (calculator.days_since_launch < setting.days_since_launch.period) {
                if (calculator.days_since_launch == 0) {
                    calculator.days_since_launch = 1;
                }

                value = (1 / calculator.days_since_launch) * setting.days_since_launch.weight;
            }

            return value;
        });
        const norm_result = computed(() => {
            return norm_revenue.value * setting.revenue.weight
                + norm_sales_quantity.value * setting.sales_quantity.weight
                + norm_conversion_percentage.value * setting.conversion_percentage.weight
                + norm_affiliate_sales_quantity.value * setting.affiliate_sales_quantity.weight
                - norm_refund.value * setting.refund.weight
                - norm_chargeback.value * setting.chargeback.weight
                + norm_days_since_launch.value;
        });
        const theorical_minimum = computed(() => {
            return - setting.refund.weight
                - setting.chargeback.weight;
        });
        const theorical_maximum = computed(() => {
            return setting.revenue.weight +
                setting.sales_quantity.weight +
                setting.conversion_percentage.weight +
                setting.affiliate_sales_quantity.weight +
                setting.days_since_launch.weight;
        });
        const speed = computed( () => {
            let value = normalizeValue(norm_result.value, theorical_minimum.value, theorical_maximum.value) * (setting.speed.max - setting.speed.min) + setting.speed.min;

            if(value > setting.speed.max) {
                value = setting.speed.max;
            } else if(value < setting.speed.min) {
                value = setting.speed.min;
            }

            return value;
        })

        const normalizacaoExemplo = computed(() =>
            katex.renderToString(
                `\\text{Normalização(Valor)} =
                \\frac{\\text{Valor} - \\text{Referência}_{\\min}}{\\text{Referência}_{\\max} - \\text{Referência}_{\\min}}
                `,
                { throwOnError: false, displayMode: true }
            )
        );

        const equacaoNormalizacao = computed(() =>
        katex.renderToString(
            `
            \\begin{aligned}
            \\text{Normalização(Receita)} &= \\frac{${calculator.revenue} - ${setting.revenue.min}}{${setting.revenue.max} - ${setting.revenue.min}} = ${norm_revenue.value} \\\\
            \\text{Normalização(Quantidade de Vendas)} &= \\frac{${calculator.sales_quantity} - ${setting.sales_quantity.min}}{${setting.sales_quantity.max} - ${setting.sales_quantity.min}} = ${norm_sales_quantity.value} \\\\
            \\text{Normalização(Percentual de Conversão)} &= \\frac{${calculator.conversion_percentage} - ${setting.conversion_percentage.min}}{${setting.conversion_percentage.max} - ${setting.conversion_percentage.min}} = ${norm_conversion_percentage.value} \\\\
            \\text{Normalização(Quantidade de Vendas de Afiliados)} &= \\frac{${calculator.affiliate_sales_quantity} - ${setting.affiliate_sales_quantity.min}}{${setting.affiliate_sales_quantity.max} - ${setting.affiliate_sales_quantity.min}} = ${norm_affiliate_sales_quantity.value} \\\\
            \\text{Normalização(Percentual de Reembolso)} &= \\frac{${calculator.refund} - ${setting.refund.min}}{${setting.refund.max} - ${setting.refund.min}} = ${norm_refund.value} \\\\
            \\text{Normalização(Percentual de Chargebacks)} &= \\frac{${calculator.chargeback} - ${setting.chargeback.min}}{${setting.chargeback.max} - ${setting.chargeback.min}} = ${norm_chargeback.value} \\\\
            \\text{Dias desde o lançamento} &= \\frac{1}{${calculator.days_since_launch}} = ${norm_days_since_launch.value}
            \\end{aligned}
            `,
            { throwOnError: false, displayMode: true }
        )
        );

        const equacaoPesos = computed(() => {
        const equacao = `
            \\text{Equação com os pesos} = \\\\[2ex]
            + (\\text{Normalização(Receita)} \\times \\text{Peso(Receita)}) \\\\
            + (\\text{Normalização(Quantidade de Vendas)} \\times \\text{Peso(Quantidade de Vendas)}) \\\\
            + (\\text{Normalização(Percentual de Conversão)} \\times \\text{Peso(Percentual de Conversão)}) \\\\
            + (\\text{Normalização(Quantidade de Vendas de Afiliados)} \\times \\text{Peso(Quantidade de Vendas de Afiliados)}) \\\\
            - (\\text{Normalização(Percentual de Reembolso)} \\times \\text{Peso(Percentual de Reembolso)}) \\\\
            - (\\text{Normalização(Percentual de Chargebacks)} \\times \\text{Peso(Percentual de Chargebacks)}) \\\\
            + (\\text{Normalização(Dias desde o lançamento)} \\times \\text{Peso(Dias desde o lançamento)}) \\\\[2ex] =

            
            (${norm_revenue.value} \\times ${setting.revenue.weight} )
            + (${norm_sales_quantity.value} \\times ${setting.sales_quantity.weight})
            + (${norm_conversion_percentage.value} \\times ${setting.conversion_percentage.weight} )
            + (${norm_affiliate_sales_quantity.value} \\times ${setting.affiliate_sales_quantity.weight} )
            - (${norm_refund.value} \\times ${setting.refund.weight} )
            - (${norm_chargeback.value} \\times ${setting.chargeback.weight})
            + (${norm_days_since_launch.value} \\times ${setting.days_since_launch.weight}) \\\\
            = ${norm_result.value}
        `;
            return katex.renderToString(equacao, { throwOnError: false, displayMode: true });
        });

        const theoricalEquation = computed(() => {
            const minPart = `Normalizado Mínimo Teórico: ${theorical_minimum.value}`;
            const maxPart = `Normalizado Máximo Teórico: ${theorical_maximum.value}`;
            
            const equacao = `
                \\begin{aligned}
                \\text{${minPart}} \\\\
                \\text{${maxPart}} \\\\
                \\text{Velocidade} =
                \\frac{(${norm_result.value}) - (${theorical_minimum.value})}{(${theorical_maximum.value}) - (${theorical_minimum.value})} 
                \\times (${setting.speed.max} - ${setting.speed.min}) + ${setting.speed.min} &= ${speed.value}
                \\end{aligned}
            `;

            return katex.renderToString(equacao, { throwOnError: false, displayMode: true });
        });

    

        return {
            selected,
            loading,
            calculator,
            setting,
            fetch,
            update,
            toast,
            normalizacaoExemplo,
            equacaoNormalizacao,
            equacaoPesos,
            theoricalEquation,
            tooltipText
        };
    },
    mounted() {
        this.fetch();
    },
};
</script>